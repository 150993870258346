import React from 'react';
import './Support.css'; // Assuming you will add some CSS for styling

const Support = () => {
  return (
    <div className="support">
      <h2>Support Us</h2>
      <div className="support-details">
        <p>
          Your generous contributions help us continue our mission to spread the word of God. 
          You can support us in the following ways:
        </p>
        <ul>
          <li>Prayers and spiritual support</li>
          <li>Volunteering</li>
          <li>Spreading our message</li>
        </ul>
        <p>Thank you for your support and may God bless you!</p>
      </div>
    </div>
  );
};

export default Support;