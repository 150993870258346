import React, { useState, useEffect } from 'react';
import api from './../../axiosConfig';
import './Witnesses.css';

const Witnesses = () => {
  const [witnesses, setWitnesses] = useState([]);
  const [approvedWitnesses, setApprovedWitnesses] = useState([]);
  const [newWitness, setNewWitness] = useState({
    name: '',
    email: '',
    profile: '',
    embedCode: '', // Change to accept YouTube embed code
  });

  useEffect(() => {
    const fetchWitnesses = async () => {
      try {
        const response = await api.get('/api/witnesses');
        setWitnesses(response.data);
      } catch (error) {
        console.error('Error fetching witnesses:', error);
      }
    };

    const fetchApprovedWitnesses = async () => {
      try {
        const response = await api.get('/api/witnesses/approved');
        setApprovedWitnesses(response.data);
      } catch (error) {
        console.error('Error fetching approved witnesses:', error);
      }
    };

    fetchWitnesses();
    fetchApprovedWitnesses();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewWitness((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post('/api/witnesses', newWitness); // Send the embed code instead of a file
      setNewWitness({ name: '', email: '', profile: '', embedCode: '' });
      const response = await api.get('/api/witnesses');
      setWitnesses(response.data);
    } catch (error) {
      console.error('Error submitting witness:', error);
    }
  };

  return (
    <div className="witnesses">
      <section className="approved-witnesses">
        <h1>Witnesses</h1>
        <p>Discover the powerful testimonies of individuals whose lives have been transformed by the love of Christ.</p>

        {approvedWitnesses.length > 0 ? (
          <div className="witness-cards">
            {approvedWitnesses.map((witness) => (
              <div className="witness-card" key={witness.id}>
                <h3 className="witness-name">{witness.name}</h3>
                <h4 className="witness-email">{witness.email}</h4>
                <p className="witness-profile">{witness.profile}</p>
                <div
                  className="video-embed"
                  dangerouslySetInnerHTML={{ __html: witness.embedCode }}
                ></div>
              </div>
            ))}
          </div>
        ) : (
          <p>No approved witnesses yet.</p>
        )}
      </section>

      <section className="submission-form">
        <h2>Share Your Stories</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={newWitness.name}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={newWitness.email}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Profile:
            <textarea
              name="profile"
              value={newWitness.profile}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            YouTube Embed Code:
            <textarea
              name="embedCode"
              value={newWitness.embedCode}
              onChange={handleInputChange}
              placeholder="Paste your YouTube embed code here"
              required
            />
          </label>
          <button type="submit">Submit</button>
        </form>
      </section>
    </div>
  );
};

export default Witnesses;
