import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }

    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [isOpen]);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">Christ Love</Link>
      </div>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li><Link to="/" onClick={closeMenu}>Home</Link></li>
        <li><a href="/talk-to-god" className="highlighted-menu">Talk to GOD</a></li>
        <li><Link to="/events" onClick={closeMenu}>Events</Link></li>
        <li><Link to="/witnesses" onClick={closeMenu}>Witnesses</Link></li>
        <li><Link to="/message-of-the-day" onClick={closeMenu}>Message of the Day</Link></li>
        <li><Link to="/support" onClick={closeMenu}>Support</Link></li>
        <li><Link to="/about-us" onClick={closeMenu}>About Us</Link></li>
      </ul>
      <div className="menu-icon" onClick={toggleMenu}>
        {isOpen ? <FaTimes size={24} color="#ecf0f1" /> : <FaBars size={24} color="#ecf0f1" />}
      </div>
    </nav>
  );
};

export default Navbar;