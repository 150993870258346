import React from 'react';
import './Home.css';

  const videos = [
    // {
    //   id: 1,
    //   title: 'ያድናል ኢየሱስ ያድናል - ዘሪቱ ከበደ || Yadinal Eyesus Yadinal - Zeritu Kebede',
    //   url: '<iframe width="1135" height="552" src="https://www.youtube.com/embed/ujhsHbrMZOY" title="ያድናል ኢየሱስ ያድናል - ዘሪቱ ከበደ ||  Yadinal Eyesus Yadinal - Zeritu Kebede" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
    // },
    {
      id: 1,
      title: 'ለኢየሱስ ነው - ዘሪቱ ከበደ || Le Eyesus Newe - Zeritu Kebede',
      url: '<iframe width="1135" height="473" src="https://www.youtube.com/embed/3q2SQub5KBg" title="ለኢየሱስ ነው - ዘሪቱ ከበደ || Le Eyesus Newe - Zeritu Kebede" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
    }
    // {
    //   id: 3,
    //   title: 'ካህኔ by Azeb Hailu አዜብ ሀይሉ - Live Concert "Dink Sitota',
    //   url: '<iframe width="1135" height="638" src="https://www.youtube.com/embed/tFD85JleeXs" title="ካህኔ  by Azeb Hailu አዜብ ሀይሉ - Live Concert &quot;Dink Sitota&quot;" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
    // },
    // {
    //     id: 4,
    //     title: 'Selamnewe ""ሰላም ነው" A New Ethiopian Gospel Song by Azeb Hailu',
    //     url: '<iframe width="1135" height="638" src="https://www.youtube.com/embed/Aj6sT013DHQ" title="Selamnewe  &quot;&quot;ሰላም ነው&quot; A New Ethiopian Gospel Song by Azeb Hailu" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
    //     },
    //     {
    //         id: 5,
    //         title: 'MercyMe - Even If (Official Lyric Video)',
    //         url: '<iframe width="780" height="439" src="https://www.youtube.com/embed/B6fA35Ved-Y" title="MercyMe - Even If (Official Lyric Video)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
    //         },
  ];

  const Home = () => {
    return (
      <div className="home">
        <h2>Welcome to Christ Love</h2>
        <p>
          At Christ Love, we are dedicated to spreading the word of God through powerful testimonies,
          inspiring messages, and uplifting songs. Join us in our journey of faith as we share the love
          of Christ with the world.
        </p>
  
        <div className="video-gallery">
          {videos.map((video) => (
            <div className="video-card" key={video.id}>
              <div
                className="video-embed"
                dangerouslySetInnerHTML={{ __html: video.url }}
              ></div>
              <h3>{video.title}</h3>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default Home;
