// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.support h2 {
  color: #e67e22;
  text-align: center;
  padding: 20px;
  margin: 20px;
}

.support-details {
  width: 100%;
  max-width: 600px; /* Adjust the max-width as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left; /* Ensure text inside is left-aligned */
  margin: 0 auto; /* Center the block horizontally */
}

.support-details p,
.support-details ul {
  width: 100%; /* Ensure paragraphs and lists take full width */
}

.support p {
  font-size: 1.2em;
  color: #2c3e50;
  margin-bottom: 20px;
}

.support ul {
  list-style-type: square;
  padding-left: 20px;
  margin: 0; /* Remove default margin */
}

.support ul li {
  margin-bottom: 10px;
  font-size: 1.1em;
  color: #34495e;
}

.support ul li::marker {
  color: #e67e22;
}`, "",{"version":3,"sources":["webpack://./src/components/Support/Support.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB,EAAE,mCAAmC;EACrD,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB,EAAE,uCAAuC;EACzD,cAAc,EAAE,kCAAkC;AACpD;;AAEA;;EAEE,WAAW,EAAE,gDAAgD;AAC/D;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,SAAS,EAAE,0BAA0B;AACvC;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".support {\r\n  padding: 20px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n}\r\n\r\n.support h2 {\r\n  color: #e67e22;\r\n  text-align: center;\r\n  padding: 20px;\r\n  margin: 20px;\r\n}\r\n\r\n.support-details {\r\n  width: 100%;\r\n  max-width: 600px; /* Adjust the max-width as needed */\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  text-align: left; /* Ensure text inside is left-aligned */\r\n  margin: 0 auto; /* Center the block horizontally */\r\n}\r\n\r\n.support-details p,\r\n.support-details ul {\r\n  width: 100%; /* Ensure paragraphs and lists take full width */\r\n}\r\n\r\n.support p {\r\n  font-size: 1.2em;\r\n  color: #2c3e50;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.support ul {\r\n  list-style-type: square;\r\n  padding-left: 20px;\r\n  margin: 0; /* Remove default margin */\r\n}\r\n\r\n.support ul li {\r\n  margin-bottom: 10px;\r\n  font-size: 1.1em;\r\n  color: #34495e;\r\n}\r\n\r\n.support ul li::marker {\r\n  color: #e67e22;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
