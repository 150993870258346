// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us {
  padding: 20px;
}

.about-us h2 {
  color: #e67e22;
  text-align: center;
}

.about-us p {
  font-size: 1.2em;
  color: #2c3e50;
  margin-bottom: 20px;
  line-height: 1.6;
}
`, "",{"version":3,"sources":["webpack://./src/components/AboutUs/AboutUs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".about-us {\r\n  padding: 20px;\r\n}\r\n\r\n.about-us h2 {\r\n  color: #e67e22;\r\n  text-align: center;\r\n}\r\n\r\n.about-us p {\r\n  font-size: 1.2em;\r\n  color: #2c3e50;\r\n  margin-bottom: 20px;\r\n  line-height: 1.6;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
