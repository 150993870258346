import React from 'react';
import './AboutUs.css'; // Assuming you will add some CSS for styling

const AboutUs = () => {
  return (
    <div className="about-us">
      <h2>About Us</h2>
      <p>
        Christ Love is a ministry dedicated to spreading the love and teachings of Jesus Christ. 
        Through our events, testimonies, and daily messages, we aim to inspire and uplift 
        individuals in their walk of faith.
      </p>
      <p>
        Our mission is to bring people closer to God and to share His love with the world. 
        We believe in the power of faith and community, and we are committed to making 
        a positive impact on the lives of those we touch.
      </p>
    </div>
  );
};

export default AboutUs;
