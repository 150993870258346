import React from 'react';
import './MessageOfTheDay.css'; // Assuming you will add some CSS for styling

const MessageOfTheDay = () => {
  const message = "For God so loved the world, that he gave his only begotten Son, that whosoever believeth in him should not perish, but have everlasting life. - John 3:16";

  return (
    <div className="message-of-the-day">
      <h2>Message of the Day</h2>
      <p>{message}</p>
    </div>
  );
};

export default MessageOfTheDay;

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import './MessageOfTheDay.css'; 

// const MessageOfTheDay = () => {
//   const [messages, setMessages] = useState([]);

//   useEffect(() => {
//     const fetchMessages = async () => {
//       try {
//         const response = await axios.get('/api/messages');
//         setMessages(response.data);
//       } catch (error) {
//         console.error('Error fetching messages:', error);
//       }
//     };

//     fetchMessages();
//   }, []);

//   return (
//     <div>
//       <h1>Messages of the Day</h1>
//       <ul>
//         {messages.map((message) => (
//           <li key={message.id}>
//             <p>{message.message}</p>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default MessageOfTheDay;


