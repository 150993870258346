// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-of-the-day {
  padding: 20px;
  text-align: center;
}

.message-of-the-day h2 {
  color: #e67e22;
}

.message-of-the-day p {
  font-size: 1.5em;
  color: #2c3e50;
  line-height: 1.6;
  border: 2px solid #8e44ad;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
}
`, "",{"version":3,"sources":["webpack://./src/components/MessageOfTheDay/MessageOfTheDay.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".message-of-the-day {\r\n  padding: 20px;\r\n  text-align: center;\r\n}\r\n\r\n.message-of-the-day h2 {\r\n  color: #e67e22;\r\n}\r\n\r\n.message-of-the-day p {\r\n  font-size: 1.5em;\r\n  color: #2c3e50;\r\n  line-height: 1.6;\r\n  border: 2px solid #8e44ad;\r\n  padding: 20px;\r\n  border-radius: 10px;\r\n  background-color: #f9f9f9;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
