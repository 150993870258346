import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import api from './../../axiosConfig';
import './Events.css';

const Events = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await api.get('/api/events');
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <div className="events">
      <h1>Upcoming Events</h1>
      <div className="events-list">
        {events.map((event) => (
          <div className="event-card" key={event.id}>
            <h2>{event.name}</h2>
            <p>{event.description}</p>
            {event.videoUrl && (
              <iframe
                src={`https://www.youtube.com/embed/${getYouTubeVideoId(event.videoUrl)}`}
                title={event.name}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="event-video"
              ></iframe>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const getYouTubeVideoId = (url) => {
  const match = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:v|embed|watch\?v=|embed\/|watch\?v=|v\/)([^"&?\/\s]{11})/);
  return match ? match[1] : '';
};

export default Events;