// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.footer h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.social-icons a {
  color: #ecf0f1;
  font-size: 1.5em;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #3498db;
}

.footer p {
  font-size: 0.9em;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".footer {\r\n  background-color: #2c3e50;\r\n  color: #ecf0f1;\r\n  padding: 20px 0;\r\n  text-align: center;\r\n}\r\n\r\n.footer-content {\r\n  max-width: 1200px;\r\n  margin: 0 auto;\r\n}\r\n\r\n.footer h3 {\r\n  font-size: 1.5em;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.social-icons {\r\n  display: flex;\r\n  justify-content: center;\r\n  gap: 20px;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.social-icons a {\r\n  color: #ecf0f1;\r\n  font-size: 1.5em;\r\n  transition: color 0.3s ease;\r\n}\r\n\r\n.social-icons a:hover {\r\n  color: #3498db;\r\n}\r\n\r\n.footer p {\r\n  font-size: 0.9em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
