import React from 'react';
import './TalkToGod.css';

const TalkToGod = () => {
  return (
    <div className="coming-soon-container">
      <h1 className="coming-soon-title">Talk to GOD</h1>
      <p className="coming-soon-description">
        A new and exciting way to interact with the Bible is coming soon! 
        Our chatbot will allow you to ask questions and receive divine guidance straight from the scriptures. Stay tuned!
      </p>
      <div className="coming-soon-highlight">
        <p>📖 "Thy word is a lamp unto my feet, and a light unto my path." - Psalm 119:105</p>
      </div>
      <p className="coming-soon-footer">Launching Soon!</p>
    </div>
  );
}

export default TalkToGod;
