import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Events from './components/Events/Events';
import Witnesses from './components/Witnesses/Witnesses';
import MessageOfTheDay from './components/MessageOfTheDay/MessageOfTheDay';
import Support from './components/Support/Support';
import AboutUs from './components/AboutUs/AboutUs';
import Footer from './components/Footer/Footer';
import TalkToGod from './components/TalkToGod/TalkToGod';
import './App.css';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/talk-to-god" element={<TalkToGod/>} />
        <Route path="/events" element={<Events />} />
        <Route path="/witnesses" element={<Witnesses />} />
        <Route path="/message-of-the-day" element={<MessageOfTheDay />} />
        <Route path="/support" element={<Support />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;

